const getPartnerPortalUrl = (partner: string): string => {
  const isProd = window.location.host.includes('.prod');
  switch (partner) {
    case 'ageless':
      return isProd ? 'https://admin.agelessrx.prod.imaware.dev' : 'https://admin.agerx.staging.imaware.dev';
    case 'brcenter':
      return isProd ? 'https://admin.br-center.prod.imaware.dev' : 'https://admin.br-center.staging.imaware.dev';
    case 'ehe':
      return isProd ? 'https://admin.ehe-health.prod.imaware.dev' : 'https://admin.ehe-health.staging.imaware.dev';
    case 'elo':
      return isProd ? 'https://admin.elo-health.prod.imaware.dev' : 'https://admin.elo.staging.imaware.dev';
    case 'favor':
      return isProd ? 'https://admin.favor.prod.imaware.dev' : 'https://admin.favor-sandbox.staging.imaware.dev';
    case 'femmistry':
      return isProd ? 'https://admin.femmistry.prod.imaware.dev' : 'https://admin.femmistry.staging.imaware.dev';
    case 'nimbus':
      return isProd ? 'https://admin.nimbus.prod.imaware.dev' : 'https://admin.nimbus.staging.imaware.dev';
    case 'noula':
      return isProd ? 'https://admin.noula-health.prod.imaware.dev' : 'https://admin.noula-sandbox.staging.imaware.dev';
    case 'outlier':
      return isProd ? 'https://admin.outlier-health.prod.imaware.dev' : 'https://admin.outlier-health.staging.imaware.dev';
    case 'parsley':
      return isProd ? 'https://admin.parsley-health.prod.imaware.dev' : 'https://admin.parsley-test.staging.imaware.dev';
    case 'recuro':
      return isProd ? 'https://admin.recuro-health.prod.imaware.dev' : 'https://admin.recuro-sandbox.staging.imaware.dev';
    case 'mayo':
      return isProd ? 'https://admin.mcurology.prod.imaware.dev/' : 'https://admin.mcurology.staging.imaware.dev/';
    case 'mnly':
      return isProd ? 'https://admin.mnly.prod.imaware.dev/' : 'https://admin.mnly.staging.imaware.dev/';
    case 'mourningwould':
      return isProd ? 'https://admin.mourningwould.prod.imaware.dev/' : 'https://admin.mourningwould.staging.imaware.dev/';
    case 'skinpass':
      return isProd ? 'https://admin.skinpass.prod.imaware.dev/' : 'https://admin.skinpass.staging.imaware.dev/';
    default:
      return isProd ? 'https://admin.imaware.prod.imaware.dev' : 'https://admin.imaware.staging.imaware.dev';
  }
};

export const getOptions = () =>
  [
    {
      value: getPartnerPortalUrl('ageless'),
      label: 'Ageless RX',
    },
    {
      value: getPartnerPortalUrl('brcenter'),
      label: 'Birth Recovery Center',
    },
    {
      value: getPartnerPortalUrl('ehe'),
      label: 'EHE',
    },
    {
      value: getPartnerPortalUrl('elo'),
      label: 'Elo Health',
    },
    {
      value: getPartnerPortalUrl('favor'),
      label: 'Favor',
    },
    {
      value: getPartnerPortalUrl('femmistry'),
      label: 'Femmistry',
    },
    {
      value: getPartnerPortalUrl('imaware'),
      label: 'imaware',
    },
    {
      value: getPartnerPortalUrl('nimbus'),
      label: 'Nimbus',
    },
    {
      value: getPartnerPortalUrl('noula'),
      label: 'Noula',
    },
    {
      value: getPartnerPortalUrl('outlier'),
      label: 'Outlier',
    },
    {
      value: getPartnerPortalUrl('parsley'),
      label: 'Parsley Health',
    },
    {
      value: getPartnerPortalUrl('recuro'),
      label: 'Recuro',
    },
    {
      value: getPartnerPortalUrl('mayo'),
      label: 'Mayo',
    },
    {
      value: getPartnerPortalUrl('mnly'),
      label: 'MNLY',
    },
    {
      value: getPartnerPortalUrl('mourningwould'),
      label: 'MourningWould',
    },
    {
      value: getPartnerPortalUrl('skinpass'),
      label: 'Skinpass',
    },
  ].sort((a, b) => (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase() ? 1 : b.label.toLocaleLowerCase() > a.label.toLocaleLowerCase() ? -1 : 0));

export const getInitialPartnerDropDownOption = (windowHost: string) => {
  const option = getOptions().find(({ value }) => value.includes(windowHost));
  return option ?? getOptions().find(({ label }) => label === 'imaware');
};
