import { Control, Controller } from 'react-hook-form';
import Select, { ActionMeta, OptionsType } from 'react-select';

import {
  Option,
  DropdownIndicator,
  MultiValueLabel,
  MultiValueRemove,
  ClearIndicator,
  ValueContainer,
  getStyles,
} from 'components/SharedDropdownComponents';

import DropdownContainer from './styles';
import { options } from './utils';
import { ReportsPageProps } from 'types';

const TestStatusDropdown = ({
  control,
}: {
  control: Control<ReportsPageProps>;
}) => {
  // eslint-disable-next-line no-unused-vars
  const handleOnChange = (
    value: OptionsType<{ value: string; label: string }>,
    action: ActionMeta<any>,
    // eslint-disable-next-line no-unused-vars
    onChange: (event: OptionsType<{ value: string; label: string }>) => void,
  ) => {
    if (
      action.action === 'select-option' &&
      action.option.value === 'Select All'
    ) {
      onChange(options);
    } else if (
      action.action === 'deselect-option' &&
      action.option.value === 'Select All'
    ) {
      onChange([]);
    } else onChange(value);
  };
  return (
    <DropdownContainer>
      <Controller
        control={control}
        name="testStatus"
        rules={{ required: true }}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <Select
            isMulti
            options={options}
            onChange={(e, action) => handleOnChange(e, action, onChange)}
            onBlur={onBlur}
            value={value}
            menuPlacement="auto"
            menuPosition="absolute"
            placeholder="Status"
            components={{
              DropdownIndicator,
              MultiValueLabel,
              MultiValueRemove,
              ValueContainer,
              ClearIndicator,
              Option,
            }}
            styles={getStyles(error)}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            allowSelectAll={true}
          />
        )}
      />
    </DropdownContainer>
  );
};

export default TestStatusDropdown;
