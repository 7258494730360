import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';

import { SearchPageProps } from 'types';
import {
  DropdownIndicator,
  getStyles,
} from 'components/SharedDropdownComponents';
import { options } from './utils';
import DropdownContainer from './styles';

const SearchDropdown = ({
  control,
  emptySearch,
  searchTerm,
}: {
  control: Control<SearchPageProps>;
  emptySearch: boolean;
  searchTerm: string;
}) => (
  <DropdownContainer data-testid="filterBySelect">
    <Controller
      control={control}
      name="searchType"
      rules={{ required: emptySearch || searchTerm }}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <Select
          options={options}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          menuPlacement="auto"
          menuPosition="fixed"
          placeholder="Filter By..."
          components={{ DropdownIndicator }}
          styles={getStyles(error)}
        />
      )}
    />
  </DropdownContainer>
);

export default SearchDropdown;
