import { OptionsType } from "react-select";

export const options: OptionsType<{ value: string; label: string }> = [
  {
    value: 'Select All',
    label: 'Select All',
  },
  {
    value: 'Shipped To Patient',
    label: 'Shipped to Patient',
  },
  {
    value: 'Delivered To Patient',
    label: 'Delivered to Patient',
  },
  {
    value: 'Shipped To Lab',
    label: 'Shipped to Lab',
  },
  {
    value: 'Delivered To Lab',
    label: 'Delivered to Lab',
  },
  {
    value: 'Received By Lab',
    label: 'Confirmed Receipt to Lab',
  },
  {
    value: 'Partially Resulted By Lab',
    label: 'Partially Resulted',
  },
  {
    value: 'Fully Resulted By Lab',
    label: 'Fully Resulted',
  },
  {
    value: 'Rejected By Lab',
    label: 'Rejected',
  },
];
