import { Control, Controller, FieldErrors } from 'react-hook-form';
import Select, { ActionMeta, OptionsType } from 'react-select';

import { PlanDefinition, ReportsPageProps } from 'types';
import DropdownContainer, { SearchError } from './styles';
import { options } from './utils';
import { Option, DropdownIndicator, MultiValueLabel, MultiValueRemove, ClearIndicator, ValueContainer, getStyles } from 'components/SharedDropdownComponents';

const TestTypeDropdown = ({
  control,
  planDefinitions,
  errors,
}: {
  control: Control<ReportsPageProps>;
  planDefinitions?: PlanDefinition[];
  errors: FieldErrors<ReportsPageProps>;
}) => {
  const formattedPlanDefinitions: { value: string; label: string; identifier: string }[] = [];
  (planDefinitions || []).forEach((planDefinition) => {
    const identifier = planDefinition?.identifier?.find((v) => v.system === 'https://imaware.health/skus')?.value;
    formattedPlanDefinitions.push({ label: planDefinition.label, value: identifier ?? '', identifier: identifier ?? '' });
  });
  const sortedOptions = formattedPlanDefinitions.sort((a, b) => (a.label < b.label) ? -1 : 1);
  const combinedTests = [...options, ...sortedOptions];

  // eslint-disable-next-line no-unused-vars
  const handleOnChange = (
    value: OptionsType<{ value: string; label: string }>,
    action: ActionMeta<any>,
    // eslint-disable-next-line no-unused-vars
    onChange: (event: OptionsType<{ value: string; label: string }>) => void,
  ) => {
    if (action.action === 'select-option' && action.option.value === 'Select All') {
      onChange(combinedTests);
    } else if (action.action === 'deselect-option' && action.option.value === 'Select All') {
      onChange([]);
    } else onChange(value);
  };

  return (
    <DropdownContainer>
      <Controller
        control={control}
        name="testType"
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            {Object.keys(errors).length ? <SearchError>Please search and/or select a category</SearchError> : null}
            <Select
              isMulti
              options={combinedTests}
              getOptionLabel={(combinedTests) => `${combinedTests.label} 
                ${combinedTests.label === 'Select All' || combinedTests.identifier === undefined ? '' : '-'}  
                ${combinedTests.identifier ?? ''}`}
              onChange={(e, action) => handleOnChange(e, action, onChange)}
              onBlur={onBlur}
              value={value}
              menuPlacement="auto"
              menuPosition="absolute"
              placeholder="Test Type"
              components={{
                DropdownIndicator,
                MultiValueLabel,
                MultiValueRemove,
                ValueContainer,
                ClearIndicator,
                Option,
              }}
              styles={getStyles(Object.keys(errors).length !== 0)}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              allowSelectAll={true}
            />
          </>
        )}
      />
    </DropdownContainer>
  );
};

export default TestTypeDropdown;
